import { Component, computed, effect, input, output, signal } from '@angular/core';
import RetornoPaginado from 'app/shared/api/common/retorno-paginado.model';

@Component({
  selector: 'sharx-paginator',
  templateUrl: './sharx-paginator.component.html',
  styleUrl: './sharx-paginator.component.scss'
})
export class SharxPaginatorComponent {
  
  paginatedResult = input<RetornoPaginado<any>>();
  onPageChange = output<number>();
  totalPages:number = 0;
  actualPage = signal(1);
  actualIndexPage = computed(() => this.actualPage() % 3 === 0 ? 3 : this.actualPage() % 3);
  arrayPages: number[] = [];

  constructor() {
    effect(() => {
      this.totalPages = this.paginatedResult()?.size!;
      this.arrayPages = Array(this.totalPages).fill(0).map((x,i)=>i+1);
    })
  }

  nextPage(): void {
    this.moveToPage(this.actualPage() + 1 < this.lastPage ? this.actualPage() + 1 : this.lastPage);
  }

  previousPage(): void {
    this.moveToPage(this.actualPage() === 1 ? 1 : this.actualPage() - 1 );
  }

  moveToPage(page: number) : void {
    this.actualPage.update(_ => page);
    this.onPageChange.emit(page);
  }

  showPages(): number[] {
    var a = this.actualPage() % 3;
    var b = this.actualPage();
    return a === 0 ? this.arrayPages.slice(b-3,b) : this.arrayPages.slice(b-a,(b-a)+3);
  }

  get lastPage(): number {
    return this.arrayPages[this.arrayPages.length-1];
  }
}
