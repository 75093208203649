import { Component, effect, input, output } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'sharx-toggle',
    templateUrl: './sharx-toggle.html',
    styleUrls: ['./sharx-toggle.scss']
  })
  export class SharxToggleComponent {
    
    localStorageKey = input<string|null>(null);
    labelBefore = input<string>();
    labelAfter = input<string>();
    onToggleChange = output<boolean>();
    value = new BehaviorSubject<boolean>(false);

    constructor() {
      effect(() => {
        this.value.next(localStorage.getItem(this.localStorageKey() ?? '') === '1');
      })

      this.value.subscribe(changeValue => {
        this.onChangeToggleValue();
      });
    }

    toggle() {
      var actual = this.value.getValue();
      this.value.next(!actual);
    }

    onChangeToggleValue() {
      var actual = this.value.getValue();
      const spanToggle = document.getElementsByClassName('toggleIcon')[0];
      if(actual) {
        spanToggle.classList.add('toggleSelected');
      } else {
        spanToggle?.classList?.remove('toggleSelected');
      }
      this.onToggleChange.emit(actual);

      if(this.localStorageKey() != null) {
        localStorage.setItem(this.localStorageKey() ?? '', !actual ? '0' : '1');
      }
    }

    get selectSide(): boolean {
      return this.value.getValue();
    }
  }