import { Injector, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { SharxToggleComponent } from './components/sharx-toogle/sharx-toggle';
import { SharxPaginatorComponent } from './components/sharx-paginator/sharx-paginator.component';
import { DirectivesModule } from './directives/directive-module';


// eslint-disable-next-line @typescript-eslint/naming-convention
export let AppInjector: Injector;

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskDirective, NgxMaskPipe,
		DirectivesModule
	],
	exports: [
		CommonModule,
		FormsModule,
        AsyncPipe,
		ReactiveFormsModule,
		MatFormFieldModule,
        MatIconModule,
        MatInputModule,
		MatButtonModule,
        MatAutocompleteModule,
		MatSliderModule,
		MatSelectModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		NgxMaskDirective, 
		NgxMaskPipe,
		DirectivesModule,
		SharxToggleComponent,
		SharxPaginatorComponent
	],
	providers: [
		CurrencyPipe,
		provideEnvironmentNgxMask()],
	declarations: [
		SharxToggleComponent,
  		SharxPaginatorComponent
	],
})
export class SharedModule {
	constructor(private injector: Injector) {
		AppInjector = this.injector;
	}
}
