export const checkNull = (value: any): boolean => {
	if (value == null) {
		return true;
	}

	if (value === null) {
		return true;
	}

	if (value === '') {
		return true;
	}

	if (typeof value === 'undefined') {
		return true;
	}

	if (value === undefined) {
		return true;
	}

	return false;
};

export const checkNullOrEmpty = (value: any): boolean => {
	if (checkNull(value)) {
		return true;
	}

	if (value === '') {
		return true;
	}

	return false;
};

export const hasNullOrEmptyProperty = (obj: any, excludeProperties: string[] = []): boolean => {
	if (Array.isArray(obj)) {
		return obj.some((a) => {
			hasNullOrEmptyProperty(a, excludeProperties);
		});
	}

	for (const key of Object.keys(obj)) {
		// Se a propriedade atual deve ser ignorada, pula para a próxima iteração.
		if (excludeProperties.includes(key)) {
			continue;
		}

		const value = obj[key];
		if (checkNull(value)) {
			console.log('Encontrou uma propriedade nula ou vazia', key);
			return true; // Encontrou uma propriedade nula ou vazia.
		} else if (isObject(value)) {
			// Se o valor for um objeto, chama a função recursivamente,
			// sem passar excludeProperties, pois eles se aplicam apenas ao nível atual do objeto.
			if (hasNullOrEmptyProperty(value, excludeProperties)) {
				console.log(' Encontrou uma propriedade nula ou vazia em um objeto filho', key);
				return true; // Encontrou uma propriedade nula ou vazia em um objeto filho.
			}
		}
	}

	return false; // Nenhuma propriedade nula ou vazia encontrada.
};

export const isObject = (value: any): boolean => {
	const a = typeof value === 'object' && value !== null;
	return a;
};
