import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { checkNull } from './check-null';

export const disableFormControls = (form: any): void => {
	for (const control of Object.keys(form.controls)) {
		form.controls[control].disable();
	}
};

export const disableFormGroup = (formGroup: FormGroup): void => {
	for (const controlName of Object.keys(formGroup.controls)) {
		const control = formGroup.get(controlName);

		if (control instanceof FormControl) {
			control.disable({ onlySelf: true, emitEvent: false });
			control.markAsUntouched();
		} else if (control instanceof FormGroup) {
			disableFormGroup(control);
		}
	}
};

export const enableFormControls = (form: any): void => {
	for (const control of Object.keys(form.controls)) {
		form.controls[control].enable();
	}
};

export const enableOrDisableFormControls = (form: any, enable: boolean): void => {
	for (const control of Object.keys(form.controls)) {
		if (enable) {
			form.controls[control].enable();
		} else {
			form.controls[control].disable();
		}
	}
};

export const markAllControlsAsDirty = (form: any): void => {
	for (const control of Object.keys(form.controls)) {
		form.controls[control].markAsDirty();
	}
};

export const markAllControlsAsPristine = (form: any): void => {
	for (const control of Object.keys(form.controls)) {
		form.controls[control].markAsPristine();
	}
};

export const markAllControlsAsUntouched = (form: any): void => {
	for (const control of Object.keys(form.controls)) {
		form.controls[control].markAsPristine();
		form.controls[control].markAsUntouched();
	}
};

export const validateAllFormFields = (formGroup: FormGroup): void => {
	Object.keys(formGroup.controls).forEach((field) => {
		const control = formGroup.get(field);

		control?.updateValueAndValidity();

		if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true });
			control.markAsDirty({ onlySelf: true });
		} else if (control instanceof FormArray) {
			control.markAsTouched({ onlySelf: true });
			control.markAsDirty({ onlySelf: true });
		} else if (control instanceof FormGroup) {
			validateAllFormFields(control);
		}
	});
};

export const validateAllFormFieldsNull = (formGroup: FormGroup): boolean => {
	let todasNulas = true;
	Object.keys(formGroup.controls).forEach((field) => {
		const control = formGroup.get(field);
		if (control?.value !== null && control?.value !== undefined && control?.value !== '') {
			todasNulas = false;
		}
	});

	return todasNulas;
};

export const validateFormArray = (formArray: FormArray): void => {
	formArray.markAsTouched({ onlySelf: true });
	formArray.markAsDirty({ onlySelf: true });
};

export const findInvalidControlsRecursive = (formToInvestigate: FormGroup | FormArray): string[] => {
	const invalidControls: string[] = [];
	const recursiveFunc = (form: FormGroup | FormArray): void => {
		Object.keys(form.controls).forEach((field) => {
			const control = form.get(field);
			if (control?.invalid) {
				invalidControls.push(field);
			}
			if (control instanceof FormGroup) {
				recursiveFunc(control);
			} else if (control instanceof FormArray) {
				recursiveFunc(control);
			}
		});
	};
	recursiveFunc(formToInvestigate);
	return invalidControls;
};

// export const getInterfaceFormValue = <T extends object>(value: any): T => {
// 	const object: T = value as T;

// 	const keys = Object.keys(object);

// 	keys.forEach((key: string) => {
// 		if (!Array.isArray(object[key]) && typeof object[key] === 'object' && !checkNull(object[key])) {
// 			object[key] = { ...object[key], ...value[key] };
// 		} else {
// 			object[key] = value[key];
// 		}
// 	});

// 	return _.cloneDeep(object);
// };

// export const getFormValue = <T extends object>(object: T, value: any): T => {
// 	const keys = Object.keys(value);

// 	keys.forEach((key: string) => {
// 		if (!Array.isArray(object[key]) && typeof object[key] === 'object' && !checkNull(object[key])) {
// 			object[key] = { ...object[key], ...value[key] };
// 		} else {
// 			object[key] = value[key];
// 		}
// 	});

// 	return _.cloneDeep(object);
// };

export const clearFormControlValue = (controls: FormControl[]): void => {
	controls.forEach((control: FormControl) => {
		control.setValue(null);
		control.updateValueAndValidity();
	});
};

export const isFormValid = (form: FormGroup): boolean => {
	validateAllFormFields(form);
	if (!form.valid) {
		setTimeout(() => scrollToError());
		console.log(findInvalidControlsRecursive(form));
		return false;
	}

	return true;
};

export const nullSafeFormGroup = (obj: any): void => {
	for (const key in obj) {
		if (key === 'isEmpty') {
			continue;
		}

		if (typeof obj[key] === 'object' && obj.hasOwnProperty(key) && obj[key] !== null) {
			nullSafeFormGroup(obj[key]);
		} else {
			if (obj[key] == null) {
				obj[key] = '';
			}
		}
	}
};

export const removerPropriedadesNulas = <T extends object>(objetoOuArray: T | T[]): Record<string, any> | Record<string, any>[] => {
	if (Array.isArray(objetoOuArray)) {
		return objetoOuArray.map((objeto) => removerPropriedadesNulas(objeto)) as T[];
	}

	const novoObjeto:Record<string, any> = {} as T;
	for (const [chave, valor] of Object.entries(objetoOuArray)) {
		if (valor !== null && valor !== undefined) {
			novoObjeto[chave] = valor;
		}
	}
	return novoObjeto;
};

export const parseToNumberValues = <T extends object>(obj: any): Record<string, any> => {
	const novoObjeto:Record<string, any> = {} as T;
	for (const [chave, valor] of Object.entries(obj)) {
		if (valor !== null && valor !== undefined && !Number.isNaN(parseInt(valor as any))) {
			novoObjeto[chave] = parseInt(valor as any);
		}
	}
	return novoObjeto;
};


const scrollToError = (): void => {
	const inputList = document.querySelectorAll('.mat-form-field-invalid');

	if (!checkNull(inputList) && inputList.length > 0) {
		const elementInput = inputList[0] as HTMLElement;
		scrollTo(elementInput);
		return;
	}
};

const scrollTo = (element: HTMLElement): void => {
	setTimeout(() => {
		element.scrollIntoView();
	}, 200);
};

export const scrollSmoothToElement = (elementId: string): void => {
	setTimeout(() => {
		const element = document.querySelector(elementId);
		element?.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}, 500);
};
