// import moment from 'moment';
import { checkNull } from './check-null';
import { ActivatedRouteSnapshot } from '@angular/router';

export const getUrlParam = (route: ActivatedRouteSnapshot | null, paramName: string): string => {
	if (!checkNull(route?.params[paramName])) {
		return route?.params[paramName];
	}

	if (checkNull(route?.parent)) {
		return '';
	}

	return getUrlParam(route?.parent ?? null, paramName);
};

export const getUrlQueryParam = (route: ActivatedRouteSnapshot | null, paramName: string): string => {
	if (!checkNull(route?.queryParams[paramName])) {
		return route?.queryParams[paramName];
	}

	if (checkNull(route?.parent)) {
		return '';
	}

	return getUrlParam(route?.parent ?? null, paramName);
};

export const setUrlParams = (url: URL, filtro: any): void => {
	Object.keys(filtro).forEach((key) => {
		let value = filtro[key];

		// if (filtro[key] instanceof Date) {
		// 	value = moment(filtro[key]).toJSON();
		// }

		// if (moment.isMoment(filtro[key])) {
		// 	value = filtro[key].toJSON();
		// }

		if (checkNull(value)) {
			return;
		}

		if (filtro[key] instanceof Array) {
			if (filtro[key].length > 0) {
				filtro[key].forEach((val: any) => {
					url.searchParams.append(key, val);
				});
			}
		} else {
			url.searchParams.append(key, value);
		}
	});
};
