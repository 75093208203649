<ng-container *ngIf="arrayPages.length > 0">
    <div class="contentNumbersPage">
        <div class="previousPage" (click)="previousPage()">
            <svg viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
        </div>
        <div class="item" *ngIf="actualPage() > 3" style="text-align: center;padding-top: 4px;"></div>
        <ng-container *ngFor="let page of showPages(); let i = index;">
            <span [ngClass]="{'actual': i+1 === actualIndexPage()}" class="numberOfPage" (click)="moveToPage(page)">{{page}}</span>
        </ng-container>
        <div class="item" *ngIf="arrayPages.length > 3 && actualPage() < lastPage" style="text-align: center;padding-top: 4px;"></div>
        <div class="nextPage" (click)="nextPage()">
            <svg viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
        </div>
    </div>
</ng-container>